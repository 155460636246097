@tailwind base;
@tailwind components;
@tailwind utilities;


body{
    font-family: 'Be Vietnam Pro', sans-serif;
}

.contactInput {
    @apply outline-none bg-slate-100 rounded-md border-b px-6 py-4 border-[#242424] text-gray-500 transition-all placeholder-gray-500 focus:border-[#39ff14]/40 focus:text-[#39ff14]/40 hover:border-[#39ff14]/40;
  }


  .show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}